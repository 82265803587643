@import '../../variables.module.scss';

.socialLinkInner{ 
  width: 30px;
  height: 30px;  
  text-align: center; 
  position: absolute;
  top: 10%;
  color: $initial-social-link-icon-color;
  
}
.socialLinkInner:hover{
  color: $initial-social-link-icon-hovering-color;
}
.socialLinkOuter{
  width: 30px;
  height: 30px;
  border-radius: 5px;
  position: relative;
  background-color: $initial-social-link-bg-color;
}
.footer-social-list{
  display: flex;
  list-style-type: none;
  margin-bottom: 0px;
}
.footer-social-listitem{
  padding-right:10px;
}
// Call To Action Button at Top Header
.site-button {
  padding: 0px 20px;
  display: inline-block;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  outline: none;
  // border-width: 0;
  border-style: solid;
  border-color: transparent;
  line-height: 1.42857;
  margin-left: 10px;
  text-decoration: none !important;
  background-color: blue;
}
